[
    {
        "started": 2023,
        "ended": 2023,
        "category": "featured",
        "done": true,
        "content": {
            "title": "Die römischen Meilensteine von Gemeinlebarn",
            "susbtitle": "Website-Projekt des Ortsarchivs Gemeinlebarn und Dominik Hagmann",
            "link": "https://meilensteine.ortsarchiv-gemeinlebarn.org"
        }
    },
    {
        "started": 2023,
        "ended": 2023,
        "category": "software",
        "done": true,
        "content": {
            "title": "DDFIY",
            "susbtitle": "PWA zur Auswahl einer zufälligen Folge der drei ???",
            "link": "https://ddfiy.jakob.fyi"
        }
    },
    {
        "started": 2023,
        "ended": 2023,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "Die ambivalente Funktion des Todes",
            "susbtitle": "Der Versuch den Todesbegriff in der Philosophie von Albert Camus zu verorten",
            "link": null
        }
    },
    {
        "started": 2023,
        "ended": 2023,
        "category": "history",
        "done": true,
        "content": {
            "title": "Fasching in Lewing",
            "susbtitle": "Ein bildlicher Rückblick auf den Fasching in Gemeinlebarn 1982 bis 2018",
            "link": "https://fasching.ortsarchiv-gemeinlebarn.org"
        }
    },
    {
        "started": 2022,
        "ended": 2023,
        "category": "featured",
        "done": true,
        "content": {
            "title": "ISDIAH Beschreibung",
            "susbtitle": "Eine umfassende und standardisierte Beschreibung des Ortsarchiv Gemeinlebarn nach ISDIAH",
            "link": "https://www.ortsarchiv-gemeinlebarn.org"
        }
    },
    {
        "started": 2022,
        "ended": 2022,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "Wovon wir reden, wenn wir von Ich und Wir reden",
            "susbtitle": "Über die Diskrepanz zwischen Autonomie und Verschmelzung im Eros",
            "link": null
        }
    },
    {
        "started": 2021,
        "ended": false,
        "category": "history",
        "done": false,
        "content": {
            "title": "Schaufenster",
            "susbtitle": "Plattform für öffentlich zugängliche Einträge des Ortsarchivs, ähnlich der bekannten Topothek",
            "link": "https://schaufenster.ortsarchiv-gemeinlebarn.org"
        }
    },
    {
        "started": 2019,
        "ended": false,
        "category": "featured",
        "done": false,
        "content": {
            "title": "Überland",
            "susbtitle": "Die Geschichte eines Zwangsarbeitslagers zwischen Gemeinlebarn und Sitzenberg-Reidling 1944/45",
            "link": "https://ueberland.jakob.fyi"
        }
    },
    {
        "started": 2021,
        "ended": false,
        "category": "history",
        "done": false,
        "content": {
            "title": "Urgeschichtliche Gesamtdarstellung",
            "susbtitle": "Übersicht über jegliche archäologische Tätigkeit in Gemeinlebarn von 1885 bis Heute",
            "link": null
        }
    },
    {
        "started": 2018,
        "ended": false,
        "category": "history",
        "done": false,
        "content": {
            "title": "Findbuch & API",
            "susbtitle": "Verwaltungsplatform und Schnittstelle für das Gemeinlebarner Ortsarchiv",
            "link": "https://findbuch.ortsarchiv-gemeinlebarn.org"
        }
    },
    {
        "started": 2021,
        "ended": 2021,
        "category": "software",
        "done": true,
        "content": {
            "title": "FFGFÜA",
            "susbtitle": "Füllapplikation für die ALFST Gemeinlebarn",
            "link": "https://ffgfuea.appartig.at"
        }
    },
    {
        "started": 2020,
        "ended": 2020,
        "category": "featured",
        "done": true,
        "content": {
            "title": "Dia Scanner",
            "susbtitle": "Automatisierte Digitalisierung von Dias und Verarbeitung",
            "link": "https://github.com/JakobVesely/reversal-film-scanner-rework-processing#reversal-film-scanner-rework-processing"
        }
    },
    {
        "started": 2017,
        "ended": 2019,
        "category": "software",
        "done": true,
        "content": {
            "title": "FFGBSY",
            "susbtitle": "Bestell- und Boniersystem der FF Gemeinlebarn - Mobile App, API & Admin-Dashboard",
            "link": "https://www.appartig.at/work/ffgbsy-ff-gemeinlebarn-bestellsystem/"
        }
    },
    {
        "started": 2019,
        "ended": 2019,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "In dubio pro malo",
            "susbtitle": "Die Probleme von Freiheit und Verantwortung im Kontext vollautomatisierter Fahrzeuge",
            "link": null
        }
    },
    {
        "started": 2019,
        "ended": 2019,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "Das Absurde und der Tod",
            "susbtitle": "Die Akzeptanz des Todes als gemeinsames Fundament für das Absurde und den Tod",
            "link": null
        }
    },
    {
        "started": 2018,
        "ended": 2019,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "Abrahams Wiederholung",
            "susbtitle": "Die „Doppelbewegung der Unendlichkeit“ als eine Form der Wiederholung bei Abraham",
            "link": null
        }
    },
    {
        "started": 2018,
        "ended": 2019,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "Der moralische Gottesbeweis in der Kritik der Urteilskraft",
            "susbtitle": "Proseminararbeit im Rahmen des Lektüreseminars „Kant – Kritik der teleologischen Urteilskraft“",
            "link": null
        }
    },
    {
        "started": 2018,
        "ended": 2018,
        "category": "philosophy",
        "done": true,
        "content": {
            "title": "Die Notwendigkeit des Strebens im supererogatorischen Raum",
            "susbtitle": "Eine Kritik an Susan Wolfs Theorie eines nicht erstrebenswerten ‚moral saint‘",
            "link": null
        }
    }
]